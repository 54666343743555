import { LogLevel } from "angular-auth-oidc-client";

// export const environment = {
//   production: false,
//   apiRoot: 'https://localhost:44320',
//   lang: 'pl',
//   apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
//   displayDateFormat: 'YYYY-MM-DD',
//   contractsGeneratorUrl: 'http://localhost:4400',
//   //identityRoot: 'https://localhost:44380',
//   identityRoot: 'https://zusticket.identity.devitmcode.pl',
//   phoneManagementApi: 'http://phonemanagementsystem.api.test.devitmcode.pl',
//   identityConfig: {
//     stsServer: 'https://zusticket.identity.devitmcode.pl',
//     redirectUrl: 'http://localhost:4400/#/authorised/dashboard',
//     clientId: 'zus_report_test',
//     responseType: 'code',
//     scope: 'openid profile IdentityServerApi offline_access ZusTicketApi',
//     postLogoutRedirectUri: 'http://localhost:4400/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     useRefreshToken: true,
//   } ,
// };



//serwer test
export const environment = {
  production: false,
  apiRoot: 'https://zusticket-api.devitmcode.pl',
  lang: 'pl',
  apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
  displayDateFormat: 'YYYY-MM-DD',
  identityRoot: 'https://zusticket-identity.devitmcode.pl',
  identityConfig: {
    stsServer: 'https://zusticket-identity.devitmcode.pl',
    redirectUrl: 'https://zusticket.devitmcode.pl/#/authorised/dashboard',
    clientId: 'zus_report_test',
    responseType: 'code',
    scope: 'openid profile IdentityServerApi offline_access ZusTicketApi',
    postLogoutRedirectUri: 'https://zusticket.devitmcode.pl/#/authorised/dashboard',
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
    renewTimeBeforeTokenExpiresInSeconds: 15,
    postLoginRoute: '/home',
    forbiddenRoute: '/forbidden',
    unauthorizedRoute: '/unauthorized',
    logLevel: LogLevel.Warn,
    useRefreshToken: true,
  } ,
};


//serwer prod
// export const environment = {
//   production: false,
//   apiRoot: 'https://ticket.api.devitmcode.pl',
//   lang: 'pl',
//   apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
//   displayDateFormat: 'YYYY-MM-DD',
//   contractsGeneratorUrl: 'http://localhost:4400',
//   identityRoot: 'https://ticket.identity.devitmcode.pl',
//   phoneManagementApi: 'http://phonemanagementsystem.api.test.devitmcode.pl',
//   identityConfig: {
//     //stsServer: 'https://phonemanagementsystem.identity.devitmcode.pl',
//     stsServer: 'https://ticket.identity.devitmcode.pl',
//     redirectUrl: 'https://ticket.devitmcode.pl/#/authorised/dashboard',
//     clientId: 'zus_report_prod',
//     responseType: 'code',
//     scope: 'openid profile IdentityServerApi offline_access ZusTicketApi',
//     postLogoutRedirectUri: 'https://ticket.devitmcode.pl/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Warn,
//     useRefreshToken: true,
//   }
// };



